import React from 'react';

import './header2.css';


const Header2 = () => {
  
  return (
    
       <section id="header">
        
            <div className="container header2_main">
                <div className="header2-content">
                  <div className='header2-text-wrapper animation-wrapper'>


                                <h1 className='title1 animation-wrapper1'>Ponos Technology</h1>
                                 <h1 className='title2 animation-wrapper2'><span className='nowrap'>Top-notch</span> R&D of core blockchain infrastructures, specialized for ZKP protocols</h1> 

                                
                                 
                                
                       
                      </div>
                  
                    
                </div> 
                
             
            </div>
          
           
              
        </section>
   
  )
}

export default Header2
